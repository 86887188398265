@font-face {
  font-family: 'icomoon';
  src:  url('../icon/icomoon.eot?x1p14q');
  src:  url('../icon/icomoon.eot?x1p14q#iefix') format('embedded-opentype'),
    url('../icon/icomoon.ttf?x1p14q') format('truetype'),
    url('../icon/icomoon.woff?x1p14q') format('woff'),
    url('../icon/icomoon.svg?x1p14q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mineria:before {
  content: "\e93e";
}
.icon-hidrocarburos:before {
  content: "\e93f";
}
.icon-gobierno:before {
  content: "\e940";
}
.icon-ambiente:before {
  content: "\e941";
}
.icon-cake:before {
  content: "\e934";
}
.icon-js-square-brands:before {
  content: "\e900";
}
.icon-chevron-up-solid:before {
  content: "\e901";
}
.icon-database:before {
  content: "\e902";
}
.icon-flecha_izquierda:before {
  content: "\e903";
}
.icon-flecha_derecha:before {
  content: "\e904";
}
.icon-lupa:before {
  content: "\e905";
}
.icon-basura:before {
  content: "\e906";
}
.icon-mision:before {
  content: "\e907";
}
.icon-politicas:before {
  content: "\e908";
}
.icon-vision:before {
  content: "\e909";
}
.icon-mas:before {
  content: "\e90a";
}
.icon-menos:before {
  content: "\e90b";
}
.icon-celular:before {
  content: "\e90c";
}
.icon-correo:before {
  content: "\e90d";
}
.icon-pais:before {
  content: "\e90e";
}
.icon-telefono:before {
  content: "\e90f";
}
.icon-ubicacion:before {
  content: "\e910";
}
.icon-navegador:before {
  content: "\e911";
}
.icon-linkedin1:before {
  content: "\e912";
}
.icon-twitter:before {
  content: "\e913";
}
.icon-facebook:before {
  content: "\e914";
}
.icon-facebook2:before {
  content: "\e915";
}
.icon-youtube:before {
  content: "\e93d";
}
.icon-ambiental:before {
  content: "\e916";
}
.icon-planeacion:before {
  content: "\e917";
}
.icon-gerencia_proyectos:before {
  content: "\e918";
}
.icon-arquitectura_software:before {
  content: "\e919";
}
.icon-generacion_mapas:before {
  content: "\e91a";
}
.icon-digitilizacion_datos:before {
  content: "\e91b";
}
.icon-metadatos:before {
  content: "\e91c";
}
.icon-migracion_datos:before {
  content: "\e91d";
}
.icon-gestion_informacion:before {
  content: "\e91e";
}
.icon-informacion_geografica:before {
  content: "\e91f";
}
.icon-gestion_procesos:before {
  content: "\e920";
}
.icon-servicio_de_aplicaciones:before {
  content: "\e921";
}
.icon-soluciones_informaticas:before {
  content: "\e922";
}
.icon-bases_datos:before {
  content: "\e923";
}
.icon-geografica:before {
  content: "\e924";
}
.icon-java:before {
  content: "\e925";
}
.icon-patrones_diseno:before {
  content: "\e926";
}
.icon-portal_server:before {
  content: "\e927";
}
.icon-desarrollo_aplicaciones:before {
  content: "\e928";
}
.icon-pasion:before {
  content: "\e929";
}
.icon-respeto:before {
  content: "\e92a";
}
.icon-responsabilidad:before {
  content: "\e92b";
}
.icon-trabajo_en_equipo:before {
  content: "\e92c";
}
.icon-calidad:before {
  content: "\e92d";
}
.icon-comunicacion:before {
  content: "\e92e";
}
.icon-confianza:before {
  content: "\e92f";
}
.icon-curiosidad:before {
  content: "\e930";
}
.icon-innovacion:before {
  content: "\e931";
}
.icon-integridad:before {
  content: "\e932";
}
.icon-lealtad:before {
  content: "\e933";
}
.icon-equipo:before {
  content: "\e935";
}
.icon-clientes:before {
  content: "\e936";
}
.icon-proyectos:before {
  content: "\e937";
}
.icon-consultoria:before {
  content: "\e938";
}
.icon-documentos:before {
  content: "\e939";
}
.icon-administracion:before {
  content: "\e93a";
}
.icon-visoresGeograficos:before {
  content: "\e93b";
}
.icon-desarrolloSitemas:before {
  content: "\e93c";
}
.icon-linkedin:before {
  content: "\eac9";
}
