@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poiret+One&display=swap');

@import '../../node_modules/swiper/swiper.min.css';
@import '../../node_modules/swiper/modules/effect-coverflow/effect-coverflow.min.css';
@import '../../node_modules/swiper/modules/effect-cards/effect-cards.min.css';

/*iconos*/
@import url('iconos.css');

@import url('base-config.css');
@import url('components-config.css');

@tailwind base;
@tailwind components;
@tailwind utilities;
