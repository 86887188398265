.boton-borde{
  border: 2px solid;
 @apply mt-8 px-7 py-2 rounded-lg opensans-700;
}

/* .boton-borde::hover{
 @apply bg-blanco;
} */

.boton-saber-mas {
  border-color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  @apply  text-blanco px-6 py-2 hover:bg-blanco hover:text-principal-amarillo;
}


 .boton-ver-mas{   
  transition: all 0.3s;
  @apply border-principal-azul text-principal-azul uppercase  hover:bg-principal-azul  hover:text-gris-claro;
 }


.boton-enviar{
  border-color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  @apply mt-5 border-principal-amarillo uppercase text-principal-amarillo hover:bg-principal-amarillo hover:text-blanco;
} 