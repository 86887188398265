.boton-navegacion{
  border-bottom: 3px solid;
  @apply border-principal-amarillo pb-5 mt-20 mb-10;
}

.boton-navegacion span{
  @apply text-principal-azul text-30 self-center;
}

.boton-navegacion .nombre-proyecto{
  @apply text-principal-rosado opensans-700 text-20;
}

.boton-navegacion .nombre-cliente{
  @apply  uppercase;
}
