.testimonios {
  @apply text-principal-azul;
}
.swiper-testimonios{
  padding: 20px !important;
}
.testimonio {
  max-width: 580px;
  border-radius: 20px;
  box-shadow: 5px 5px 19px 0px rgba(0, 0, 0, 0.39);
  @apply text-principal-azul bg-blanco;
}
.testimonio .texto {
  @apply text-18 px-2 py-5 md:py-10 lg:py-5 md:pl-4 md:pr-8 flex flex-col justify-center;
}
.testimonio .texto p {
  @apply text-14 mb-3;
}
.testimonio .informacion {
  @apply rounded-full flex text-blanco;
}
.testimonio .datosPerso{
  @apply rounded-full flex text-blanco;
}
.testimonio .datos {
  border-radius: 20px 0 0 20px;
  @apply flex flex-col items-center justify-center py-5 pl-8 text-blanco;
}
.testimonio .datos h2,
.testimonio .datos p {
  @apply text-blanco;
}
.testimonio .foto {
  height: 150px;
  width: 150px;
  @apply rounded-full border-8 mb-5 mx-auto;
}
.testimonio .nombre {
  @apply uppercase opensans-700 text-right mt-4;
}
.testimonio .empresa {
  @apply uppercase opensans-400 text-center mb-2;
}
.testimonio .redes {
  @apply justify-end;
}
.testimonio .comilla{
  font-size: 2.5em !important;
  @apply uppercase merriweather-400 pb-2;
}