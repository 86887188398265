@import './cifras.css';
@import './clientes.css';
@import './saberMas.css';
@import './testimonios.css';
@import './nosotros.css';

.swiper-pagination-bullet{
  background-color: #fff !important;
  height: 10px !important;
  width: 10px !important;
}
.swiper-pagination-bullet-active{
  background-color: #fff !important;
}