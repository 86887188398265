.tabla-servicios span {
  @apply text-principal-azul-claro;
}

.descripcion-subservicio p {
  @apply text-30;
}

.descripcion-subservicio p {
  @apply text-24;
}

.descripcion-subservicio .texto-resaltado {
  @apply text-principal-azul-claro;
}

/* Ficha servicios */
.franja-subservicios {
  @apply pb-0;
}

.franja-subservicios span {
  @apply text-principal-azul;
}

.franja-subservicios .titulo {
  @apply text-blanco;
}

.caja-servicio p {
  @apply mb-4;
}

.caja-servicio .boton-dropdown p {
  @apply mb-0;
}

.caja-servicio h3 {
  @apply uppercase mb-2 merriweather-700 text-principal-amarillo;
}

.caja-servicio:nth-child(even) {
  box-shadow: 0px 0px 14px 5px rgba(0, 0, 0, 0.1);
  @apply bg-gris-claro;
}

.caja-servicio:nth-child(even) .info {
  @apply bg-gris-claro  flex-row-reverse;
}

.caja-servicio:nth-child(even) h3 {
  @apply text-principal-azul;
}

.caja-servicio:last-child {
  @apply pb-12;
}

.seccion-servicios{
  margin-top: -20px;
}

.servicios-menu {
  @apply text-blanco z-50;
}

.servicios-menu p {
  @apply text-blanco;
}

.servicios-menu .servicio-opcion {
  cursor: pointer;
  /* transition: 0.4s all; */
}

.servicios-menu .servicio-activo {
  border-radius: 20px 20px 0 0;
  @apply bg-blanco text-principal-amarillo relative;
}

.servicios-menu .servicio-activo p {
  @apply bg-blanco text-principal-amarillo;
}

@media only screen and (min-width: 1200px) {
  .caja-servicio:nth-child(even) .col-izq {
    @apply pr-16;
  }

  .caja-servicio:nth-child(odd) .col-izq {
    @apply pl-16;
  }
}
