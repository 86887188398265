.card-proyecto{
  height: 380px;
  perspective: 1000px;
}
.card-proyecto-inner{
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-proyecto:hover .card-proyecto-inner {
  transform: rotateY(180deg);
}

.card-proyecto-front, .card-proyecto-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-proyecto-back {
  transform: rotateY(180deg);
}

.proyecto{
  height: 380px;
  border-radius: 20px;
}

.proyecto .informacion {
  border-radius: 0 0 19px 19px;
}

.proyecto .informacion p{
  @apply text-blanco;
}

.proyecto .informacion .sector{
  @apply p-2 text-terciaria-amarillo;
}

.proyecto .informacion .nombre {
  @apply uppercase opensans-700 mb-2;
}

.proyecto .informacion .empresa {
  @apply uppercase opensans-400 mb-2;
}

.thumb-proyecto .titulo{
  @apply px-10 pb-10 bg-terciaria-amarillo; 
}

.info-proyecto {
  @apply bg-blanco p-4 border-4 border-principal-amarillo;
}
.info-proyecto .nombre {
  @apply uppercase opensans-700 text-14 text-principal-azul-claro;
}
.info-proyecto .empresa {
  @apply uppercase opensans-700 text-12 opacity-50;
}

.info-proyecto .boton-saber-mas {
  @apply bg-blanco border-principal-azul-claro border-2 text-principal-azul-claro py-1 hover:bg-principal-azul-claro hover:text-blanco;
}

.info-proyecto .descripcion-proyecto{
  overflow-y: auto;
  padding: 0 15px 15px 0;
}

.info-proyecto .descripcion-proyecto p{
  @apply mb-1 text-14;
}


.info-proyecto .descripcion-proyecto ul{
  list-style: disc;
  padding: revert;
}


.card-proyecto2{
  cursor: pointer;
  height: 300px;
  perspective: 1000px;
}

.card-proyecto2-flip .card-proyecto-inner {
  transform: rotateY(180deg);
}

.proyecto2{
  height: 300px;
  border-radius: 20px;
}

.proyecto2 .informacion {
  border-radius: 0 0 19px 19px;
}

.proyecto2 .nombre {
  @apply uppercase opensans-700 text-16 text-principal-azul-claro;
}

.proyecto2 .informacion p{
  @apply text-blanco;
}

.proyecto2 .informacion .nombre {
  @apply uppercase opensans-700 mb-0;
}
