.mascara-contacto {
  background-blend-mode: multiply;
  bottom: 0;
  clip-path: polygon(0 72%, 100% 30%, 100% 100%, 0% 100%);
  height: 100%;
  left: 0;
  position: absolute;
  width: 100vw;
  z-index: -1;
  @apply bg-principal-amarillo;
}

.mascara-contacto-2 {
  background-blend-mode: multiply;
  bottom: 0;
  clip-path: none;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100vw;
  z-index: -2;
  @apply bg-gris-claro;
}

.contacto {
  @apply relative;
}

.formulario-contacto {
  border-radius: 20px;
  box-shadow: 4px 7px 13px 0px rgba(0, 0, 0, 0.25);
  @apply bg-blanco py-5 lg:py-10 px-5 lg:px-10;
}

.formulario-contacto h3 {
  @apply uppercase lg:text-36 merriweather-700 text-principal-amarillo;
}

.info-formulario {
  @apply mb-5;
}

.formulario-contacto .campos-formulario div {
  @apply flex flex-col mb-4;
}

.formulario-contacto .campos-formulario input,
.formulario-contacto .campos-formulario textarea {
  border-bottom-width: 1px;
  @apply py-2 border-b-principal-azul-claro focus:outline-none focus:border-b-principal-amarillo focus:border-b-2;
}

.formulario-contacto .campos-formulario .error-input {
  @apply border-b-principal-rosado border-b-2 focus:outline-none focus:border-b-principal-rosado;
}

.formulario-contacto label {
  @apply opensans-600 text-principal-azul-medio uppercase;
}

.formulario-contacto span {
  @apply opensans-700 text-principal-rosado uppercase;
}

.text-input-advertencia {
  @apply text-principal-rosado text-15 opensans-600;
}

.mapa-contacto::after,
.mapa-contacto::before {
  content: none;
}

.mapa-contacto {
  height: 300px;
  width: 300px;
  left: 28px;
  @apply p-0 mt-16 lg:mt-0 mb-8 lg:mb-16;
}

.mapa-contacto .mapa-borde {
  width: 250px;
  height: 250px;
  @apply bg-principal-azul;
}

.mapa-contacto .mapa {
  width: auto;
}

.contacto .info-contacto span {
  @apply text-principal-azul;
}

@media only screen and (min-width: 435px) {
  .mapa-contacto{
    left: 0;
    height: 450px;
    width: 450px;
  }
  
  .mapa-contacto .mapa-borde{
    width: 360px;
    height: 360px;
  }
}
