/* Clientes */
.clientes h3 {
  @apply text-principal-amarillo-oscuro;
}

.clientes p {
  @apply opensans-400 text-center;
}

.clientes .resaltados {
  @apply text-principal-azul;
}

.clientes .cliente {
  max-width: 300px;
}
.clientes .swiper-wrapper {
  @apply items-center;
}
