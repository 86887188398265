.imagen-fondo {
  background-position: center;
  background-size: cover;
}

body {
  background-image: url("../../img/fondos/curvas_nivel_80.png");
  @apply bg-cover;
}

.logo-inascol {
  max-width: 180px;
}

.titulo-subsecciones {
  @apply uppercase merriweather-700 text-36 text-center mb-2;
}

.resaltados {
  @apply opensans-700 text-20;
}

.fondo-puntas {
  height: 20px;
  @apply relative;
}

.fondo-puntas svg {
  width: 200vw;
  height: 100%;
  left: -100vw;
  @apply absolute;
}

.ondas-verticales {
  height: 100%;
}

.titulo-seccion {
  @apply bg-cover bg-principal-azul pb-10 text-center;
}

.container-titulo-seccion svg {
  transform: rotate(180deg);
  left: 0vw;
}

.titulo-seccion h2 {
  @apply text-40 merriweather-400 text-blanco;
}

/* Caja azul */
.caja-ondas h3 {
  @apply text-principal-amarillo;
}
.caja-ondas .descripcion {
  width: 90%;
  @apply text-blanco mx-auto opensans-300 my-10 text-center;
}

.caja-ondas p {
  @apply text-blanco;
}
.caja-ondas span {
  @apply text-principal-amarillo;
}

/* dropdown */
.dropdown .boton-dropdown {
  border-width: 2px 0 0;
  @apply py-3 px-4 border-gris;
}

.dropdown .icon-mas,
.dropdown .icon-menos {
  @apply text-terciaria-morado-claro text-24;
}

.dropdown .informacion-seccion {
  @apply border-gris overflow-hidden z-20;
  @apply px-4 transition-all duration-500;
}

.dropdown .informacion-seccion div {
  @apply py-4;
}

.dropdown .boton-dropdown p {
  @apply text-gris-oscuro opensans-700 flex items-center text-left;
}

.dropdown .icono-seccion {
  @apply text-principal-azul-claro text-24;
}

.texto-resaltado {
  @apply text-principal-amarillo opensans-700;
}

.fondo-amarillo {
  background-image: url("../../img/fondos/modelo_elevacion.png");
  background-position: center 77%;
  color: #fff;
  @apply text-center bg-cover bg-no-repeat bg-principal-amarillo py-14;
}

.foto-redondeada {
  border-radius: 20px;
  box-shadow: 4px 7px 13px 0px rgba(0, 0, 0, 0.25);
  width: 100% !important;
}

.lista-desordenada {
  @apply list-inside list-disc mb-4;
}

.sublista-desordenada {
  @apply list-inside list-disc mb-4 ml-8;
}

.borde {
  border: 10px solid;
}

@media only screen and (min-width: 1200px) {
  .logo-inascol {
    max-width: 200px;
  }
}
