/* Cifras */
.nuestro-trabajo h3 {
  @apply text-principal-azul;
}

.nuestro-trabajo .cifra {
  @apply lg:px-3 mb-5 lg:mb-0;
}
.nuestro-trabajo .cifra2 span {
  @apply text-100;
}
.nuestro-trabajo p {
  @apply uppercase text-gris-oscuro text-18;
}
.nuestro-trabajo .cifra-importante {
  @apply opensans-800 text-50 text-principal-amarillo uppercase;
}
.nuestro-trabajo span {
  @apply text-100 lg:text-140 text-principal-azul-claro;
}
.nuestro-trabajo p span {
  @apply opensans-800 text-50 text-principal-amarillo uppercase;
}

.cifra-proyectos span {
  @apply text-100;
}

.cifra-proyectos .cifra-importante {
  margin-top: -21px;
}

.cifra-equipo .cifra-importante {
  @apply text-100;
}

.cifra-equipo span {
  @apply text-100;
}

.cifra-equipo .texto {
  margin-top: -20px;
}

.cifra-clientes .cifra-importante {
  @apply text-60;
}

.cifra-clientes span {
  @apply text-100;
}
