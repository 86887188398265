@layer base{
  h1, h2, h3, h4, h5, h6{
    @apply font-merriweather;
  }
  h3{
    @apply text-30;
  }
  a, p, li{
    @apply text-18;
    @apply font-opensans;
  }
  p, li, textarea, input{
    @apply text-gris-oscuro
  }
  html{
    @apply overflow-x-hidden lg:overflow-x-visible 
  }
  body{
    background-image: url("../img/fondos/curvas_nivel_80.png");
    overflow-x: hidden;
    @apply bg-center bg-fixed;
  }
}
