footer {
  margin-top: -2.5rem;
}
.footer {
  background-image: url("../../img/fondos/fondo_cuadros.png");
  background-size: auto 130%;
  margin-top: -2px;
  @apply bg-right-top bg-no-repeat bg-principal-azul pt-5;
}

.info-contacto {
  @apply mt-5;
}

.info-contacto p,
.info-contacto a {
  @apply text-blanco text-18;
}

.info-contacto span {
  @apply text-principal-amarillo;
}

.partner h2 {
  @apply text-28 mb-4 text-principal-amarillo;
}

.partner .logo-esri {
  max-width: 300px;
}

.redes .red {
  height: 50px;
  width: 50px;
  @apply border-2 cursor-pointer rounded-full text-blanco hover:text-principal-amarillo-claro;
}

.redes .red a {
  @apply text-24;
}

.mapa {
  margin-left: -58px;
  height: 450px;
  padding: 70px;
  width: 450px;
  @apply relative md:ml-0 lg:my-5;
}

.mapa::before {
  bottom: 0;
  content: "";
  height: 10px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 100%;
  @apply bg-principal-amarillo;
}

.mapa::after {
  content: "";
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 10px;
  z-index: 1;
  @apply bg-principal-amarillo;
}

.mapa-borde {
  border-radius: 20px;
  height: 248px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  transform: rotate(45deg);
  width: 248px;
  z-index: 10;
  @apply bg-principal-amarillo;
}

.mapa iframe {
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  z-index: 100;
}

.derechos-reservados {
  @apply relative;
}

.derechos-reservados p {
  @apply text-principal-amarillo;
}

.derechos-reservados::before {
  content: "";
  width: 200vw;
  position: absolute;
  height: 2px;
  left: -100%;
  top: 0;
  border-top: 2px solid white;
  @apply border-dashed pt-4;
}

@media only screen and (min-width: 435px) {
  .mapa {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .mapa {
    margin-left: 0px;
    height: 500px;
    width: 500px;
  }

  .mapa-borde {
    height: 285px;
    width: 285px;
  }
}
