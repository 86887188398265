header {
  /* background-image: url('../../img/fondos/curvas_nivel_50.png'); */
  /* box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3); */
  transition: all 0.4s;
  width: 100vw;
  z-index: 200;
  @apply bg-center top-0;
}

.header-menu {
  @apply opensans-600;
}

.header-menu .opcion {
  @apply mb-2 hover:text-principal-amarillo;
}
.hamburger-react {
  @apply text-blanco;
}

.menu-movil {
  background-color: #fff;
  height: 100vh;
  max-width: 270px;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s;
  z-index: 10000000;
}

.menu-movil a div {
  @apply border-b-2 border-principal-amarillo-claro px-4 text-gris-oscuro;
}

.menu-movil .items-menu {
  @apply m-0;
}

.opcion-activa-movil div {
  color: #fff !important;
  @apply bg-principal-amarillo opensans-700;
}

.menu-open{
  left: 0;
}

.menu-close{
  left: -270px;
}

.cerrar-menu{
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1000;
} 